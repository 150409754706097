import Home from './pages/home';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import stcRoutes from './Routes';
import {Link} from 'react-router-dom';

import Header from './pages/header';

import About from './pages/about';
import Contact from './pages/contact';

// import Navigation from './node_modules/Navigation';
import logo from './logo.svg';
// import FunctionalComponent from './FunctionalComponent';
// import './App.css';

function App() {
    return (
        <Router>
            
           

            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contact-us" element={<Contact />} />
                <Route path="/about-us" element={<About />} />
                {/* Add more routes here as needed */}
            </Routes>
        </Router>
    );
}

export default App;
