import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import Header from './pages/header';
import Footer from './pages/footer';
import Home from './pages/home';
import About from './pages/about';
import Contact from './pages/contact';




class stcRoutes extends React.Component{
	render(){
		return(
			<Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/contact-us" element={<Contact />} />
                <Route path="/about-us" element={<About />} />
                {/* Add more routes here as needed */}
            </Routes>
        </Router>

		)
	}
}


export default stcRoutes;